exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-competences-tsx": () => import("./../../../src/pages/competences.tsx" /* webpackChunkName: "component---src-pages-competences-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hardware-tsx": () => import("./../../../src/pages/hardware.tsx" /* webpackChunkName: "component---src-pages-hardware-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-software-tsx": () => import("./../../../src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-team-team-json-team-tsx": () => import("./../../../src/pages/team/{TeamJson.team}.tsx" /* webpackChunkName: "component---src-pages-team-team-json-team-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-undercontruction-tsx": () => import("./../../../src/pages/undercontruction.tsx" /* webpackChunkName: "component---src-pages-undercontruction-tsx" */)
}

